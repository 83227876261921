<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="m-0">Proyección Tarifa Adicional</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">
                    Proyección Tarifa Adicional
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <!-- Formulario para filtros -->
              <section
                :class="
                  total_viajes ||
                  semaforo_tiempos.cargue ||
                  semaforo_tiempos.descargue
                    ? 'col-lg-7 col-md-12 col-sm-12'
                    : 'col-12'
                "
              >
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <div class="row">
                      <div class="col-md-6">
                        <h5>Filtros</h5>
                      </div>
                      <div class="col-md-6">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn bg-navy btn-sm"
                            @click="getIndex()"
                          >
                            <i class="fas fa-search"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="limpiar()"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-success"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.proyeccionesStandby.DescargarExcel'
                              )
                            "
                            @click="generarListadoExcel()"
                          >
                            <i class="far fa-file-excel"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <div class="direct-chat-messages" style="height: 300px">
                      <div id="accordion">
                        <!-- Acordeón de filtros -->
                        <div class="card card-secondary mb-1">
                          <div class="card-header">
                            <a
                              data-toggle="collapse"
                              href="#collapse"
                              aria-expanded="false"
                              class="d-block w-100 collapsed"
                            >
                              <h4 class="card-title w-100">
                                <i class="fas fa-filter mr-2"></i>
                                Filtros
                              </h4>
                            </a>
                          </div>
                          <div
                            id="collapse"
                            data-parent="#accordion"
                            class="collapse"
                          >
                            <div class="card-body">
                              <div class="row">
                                <div class="form-group col-md-3">
                                  <label>N° Viaje</label>
                                  <input
                                    type="number"
                                    v-model="filtros.id"
                                    placeholder="N° viaje"
                                    label="id"
                                    class="form-control form-control-sm p-0"
                                    @keyup.enter="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-3">
                                  <label>N° Guía</label>
                                  <input
                                    type="number"
                                    v-model="filtros.guia_id"
                                    placeholder="N° guia"
                                    label="guia_id"
                                    class="form-control form-control-sm p-0"
                                    @keyup.enter="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-3">
                                  <label>Tip. Combustible</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="tipo_combustible"
                                    placeholder="Combustible"
                                    label="descripcion"
                                    :options="listasForms.tiposCombustible"
                                    @input="seleccionarTipoCombustible()"
                                  ></v-select>
                                </div>
                                <!-- <div class="form-group col-md-3">
                                  <label>Vehículo</label>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Placa"
                                    v-model="filtros.vehiculo_id"
                                  />
                                </div> -->
                                <div class="form-group col-md-3">
                                  <label>Vehiculo</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="filtros.vehiculo"
                                    placeholder="Placa"
                                    label="placa"
                                    :options="listasForms.vehiculos"
                                    @search="buscarVehiculos"
                                    @input="selectVehiculo()"
                                    multiple
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>Producto</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="producto"
                                    placeholder="Nombre"
                                    label="nombre"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.productos"
                                    @input="seleccionarProducto()"
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>Transportadora</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="transportadora"
                                    placeholder="Razon social"
                                    label="razon_social"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.empresas"
                                    @input="seleccionarTransportadora()"
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Sitio cargue</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="sitio_cargue"
                                    placeholder="Sitio cargue"
                                    label="nombre"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.sitios"
                                    @input="seleccionarSitioCargue()"
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Sitio descargue</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="sitio_descargue"
                                    placeholder="Sitio descargue"
                                    label="nombre"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.sitios"
                                    @input="seleccionarSitioDescargue()"
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Ruta</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="ruta"
                                    placeholder="Nombre"
                                    label="nombre"
                                    class="form-control form-control-sm p-0"
                                    :options="listasForms.rutas"
                                    @input="seleccionarRuta()"
                                  ></v-select>
                                </div>
                                <div class="form-group col-md-3">
                                  <label>Tipo último sitio:</label>
                                  <select
                                    class="form-control form-control-sm p-0"
                                    v-model="filtros.sitio_type"
                                  >
                                    <option value="">Seleccione...</option>
                                    <option
                                      v-for="type in listasForms.sitio_types"
                                      :key="type.numeracion"
                                      :value="type.numeracion"
                                    >
                                      {{ type.descripcion }}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group col-md-3">
                                  <div class="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      id="obvs"
                                      v-model="filtros.check_guia"
                                      class="custom-control-input"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="obvs"
                                      >Sin Guia</label
                                    >
                                  </div>
                                </div>
                                <div
                                  class="form-group col-md-9"
                                  v-if="filtros.sitio_type == 2"
                                >
                                  <label>Último Punto Gestión</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="ultimo_punto"
                                    placeholder="Punto Gestión"
                                    label="nombre"
                                    :options="listasForms.puntos_gestion"
                                    @input="seleccionarSitio"
                                  ></v-select>
                                </div>
                                <div
                                  class="form-group col-md-9"
                                  v-if="filtros.sitio_type == 1"
                                >
                                  <label>Último Sitio</label>
                                  <v-select
                                    :class="
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : ''
                                    "
                                    v-model="ultimo_punto"
                                    placeholder="Sitio"
                                    label="nombre"
                                    :options="listasForms.sitios"
                                    @input="seleccionarSitio"
                                  ></v-select>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-4">
                                  <label>Fecha Cargue</label>
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="filtros.fecha_cargue"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Fecha Descargue</label>
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="filtros.fecha_descargue"
                                    @input="getIndex()"
                                  />
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Estado</label>
                                  <select
                                    class="form-control form-control-sm"
                                    v-model="filtros.estado"
                                    @change="getIndex()"
                                  >
                                    <option value="">Seleccione...</option>
                                    <option
                                      v-for="estado in listasForms.estados"
                                      :key="estado.numeracion"
                                      :value="estado.numeracion"
                                    >
                                      {{ estado.descripcion }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Acordeón de fechas -->
                        <div class="card card-secondary mb-1">
                          <div class="card-header">
                            <a
                              data-toggle="collapse"
                              href="#collapse_1"
                              aria-expanded="false"
                              class="d-block w-100 collapsed"
                            >
                              <h4 class="card-title w-100">
                                <i class="fas fa-calendar-alt mr-2"></i>
                                Tiempos Estimados
                              </h4>
                            </a>
                          </div>
                          <div
                            id="collapse_1"
                            data-parent="#accordion"
                            class="collapse"
                          >
                            <div class="card-body">
                              <div class="row float-center">
                                <div class="form-group col-md-12">
                                  <label>Estimado en Cargue</label>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <small>Enturne</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.cargue_fecha_enturne"
                                        @input="validaFechasCargue()"
                                      />
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="filtros.cargue_hora_enturne"
                                        :class="
                                          $v.filtros.cargue_hora_enturne
                                            .$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                        v-mask="{
                                          mask: '99:99',
                                          hourFormat: '24',
                                        }"
                                        @input="validaFechasCargue()"
                                      />
                                    </div>
                                    <!--   -->
                                    <div class="col-md-6">
                                      <small>Cargue</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.cargue_fecha"
                                        @input="validaFechasCargue()"
                                      />
                                      <!-- <input
                                        type="time"
                                        class="form-control form-control-sm"
                                        v-model="filtros.cargue_hora"
                                        @input="validaFechasCargue()"
                                      /> -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="filtros.cargue_hora"
                                        :class="
                                          $v.filtros.cargue_hora.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                        v-mask="{
                                          mask: '99:99',
                                          hourFormat: '24',
                                        }"
                                        @input="validaFechasCargue()"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group col-md-12">
                                  <label>Estimado en Descargue</label>
                                  <div class="row">
                                    <!-- <div class="col-md-6">
                                      <small>Enturne</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="
                                          filtros.descargue_fecha_enturne
                                        "
                                        @input="validaFechasDescargue()"
                                      />
                                      <input
                                        type="time"
                                        class="form-control form-control-sm"
                                        v-model="filtros.descargue_hora_enturne"
                                        @input="validaFechasDescargue()"
                                      />
                                    </div> -->
                                    <div class="col-md-6">
                                      <small>Descargue</small>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="filtros.descargue_fecha"
                                        @input="validaFechasDescargue()"
                                      />
                                      <!-- <input
                                        type="time"
                                        class="form-control form-control-sm"
                                        v-model="filtros.descargue_hora"
                                        @input="validaFechasDescargue()"
                                      /> -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="filtros.descargue_hora"
                                        :class="
                                          $v.filtros.descargue_hora.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                        v-mask="{
                                          mask: '99:99',
                                          hourFormat: '24',
                                        }"
                                        @input="validaFechasDescargue()"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Resumen generado -->
              <section
                class="col-lg-5 col-md-12 col-sm-12"
                v-if="
                  total_viajes ||
                  semaforo_tiempos.cargue ||
                  semaforo_tiempos.descargue
                "
              >
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <div class="row">
                      <div class="col-md-6">
                        <h5>Informe</h5>
                      </div>
                      <div class="col-md-6 text-right">
                        <h5>
                          <span class="badge badge-pill bg-secondary">
                            {{ total_viajes.total_general }}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <div class="direct-chat-messages" style="height: 300px">
                      <div class="row">
                        <div
                          class="col-lg-6 col-md-6 col-sm-12"
                          v-if="semaforo_tiempos.cargue"
                        >
                          <div class="small-box bg-secondary mb-1">
                            <div class="inner">
                              <h3 style="font-size: 2em">Cargues</h3>
                              <h3
                                style="font-size: 1em"
                                v-for="(
                                  cargue, index
                                ) in semaforo_tiempos.cargue"
                                :key="index"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input checkCargue"
                                    type="checkbox"
                                    :id="`cargue_${index}`"
                                    v-model="filtros_estado_cargue"
                                    :value="index"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="`cargue_${index}`"
                                  >
                                    {{ cargue.label }}
                                    <small>{{ cargue.porcentaje }}</small
                                    >:
                                    <span
                                      class="badge badge-pill"
                                      :class="
                                        index == 'normal'
                                          ? 'bg-success'
                                          : index == 'adecuado'
                                          ? 'bg-lime'
                                          : index == 'critico'
                                          ? 'bg-warning'
                                          : index == 'muy_critico'
                                          ? 'bg-orange'
                                          : index == 'genero'
                                          ? 'bg-danger'
                                          : 'bg-secondary'
                                      "
                                    >
                                      {{ cargue.cantidad }}
                                    </span>
                                  </label>
                                </div>
                              </h3>
                              <h4 class="text-right">
                                <span class="badge badge-pill bg-navy">
                                  {{
                                    total_viajes && total_viajes.total_cargue
                                      ? total_viajes.total_cargue
                                      : "$0,00"
                                  }}
                                </span>
                              </h4>
                            </div>
                            <div class="icon">
                              <i class="ion ion-pie-graph"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-6 col-md-6 col-sm-12"
                          v-if="semaforo_tiempos.descargue"
                        >
                          <div class="small-box bg-secondary mb-1">
                            <div class="inner">
                              <h3 style="font-size: 2em">Descargues</h3>
                              <h3
                                style="font-size: 1em"
                                v-for="(
                                  descargue, index
                                ) in semaforo_tiempos.descargue"
                                :key="index"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input checkDescargue"
                                    type="checkbox"
                                    v-model="filtros_estado_descargue"
                                    :id="`descargue_${index}`"
                                    :value="index"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="`descargue_${index}`"
                                  >
                                    {{ descargue.label }}
                                    <small>{{ descargue.porcentaje }}</small>
                                    :
                                    <span
                                      class="badge badge-pill"
                                      :class="
                                        index == 'normal'
                                          ? 'bg-success'
                                          : index == 'adecuado'
                                          ? 'bg-lime'
                                          : index == 'critico'
                                          ? 'bg-warning'
                                          : index == 'muy_critico'
                                          ? 'bg-orange'
                                          : index == 'genero'
                                          ? 'bg-danger'
                                          : 'bg-secondary'
                                      "
                                    >
                                      {{ descargue.cantidad }}
                                    </span>
                                  </label>
                                </div>
                              </h3>
                              <h4 class="text-right">
                                <span class="badge badge-pill bg-info">
                                  {{
                                    total_viajes && total_viajes.total_descargue
                                      ? total_viajes.total_descargue
                                      : "$0,00"
                                  }}
                                </span>
                              </h4>
                            </div>
                            <div class="icon">
                              <i class="ion ion-pie-graph"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Tabla de contenido -->
              <section class="col-12">
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <div class="row">
                      <div class="col-md-11">
                        <h5>Detalles</h5>
                      </div>
                    </div>
                  </div>
                  <div class="card-body table-responsive p-0">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="table table-bordered table-striped table-hover table-sm text-nowrap m-0"
                          style="font-size: 0.8em"
                        >
                          <thead class="thead">
                            <tr>
                              <th rowspan="2"></th>
                              <th rowspan="2">Viaje</th>
                              <th rowspan="2">Guía</th>
                              <th rowspan="2">Vehículo</th>
                              <th rowspan="2">Transportadora</th>
                              <th rowspan="2">Producto</th>
                              <th rowspan="2">Sitio cargue</th>
                              <th rowspan="2">Sitio descargue</th>
                              <th rowspan="2">Último punto</th>
                              <th class="bg-navy text-center" colspan="6">
                                Cargue
                              </th>
                              <th class="bg-info text-center" colspan="6">
                                Descargue
                              </th>
                              <th rowspan="2">Estado</th>
                            </tr>
                            <tr>
                              <th class="bg-navy pl-1">Tiempo TA</th>
                              <th class="bg-navy">Valor TA</th>
                              <th class="bg-navy">Fecha Enturne</th>
                              <th class="bg-navy">
                                Tiempo <small>Horas</small>
                              </th>
                              <th class="bg-navy">Fecha Max. Cargue</th>
                              <th class="bg-navy">Fecha Cargue</th>
                              <th class="bg-info">Tiempo TA</th>
                              <th class="bg-info">Valor TA</th>
                              <th class="bg-info">Vencimiento Guía</th>
                              <th class="bg-info">
                                Tiempo <small>Horas</small>
                              </th>
                              <th class="bg-info">Fecha Max. Descargue</th>
                              <th class="bg-info">Fecha Descargue</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in filtrarEstados" :key="item.id">
                              <td>
                                <div class="btn-group float-center">
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    data-toggle="modal"
                                    data-target="#Modal_fechas"
                                    @click="getDataFila(item.id, item.guia_id)"
                                  >
                                    <i class="fas fa-calendar-alt"></i>
                                  </button>
                                </div>
                              </td>
                              <td class="float-center">
                                <span class="badge badge-default">{{
                                  item.id
                                }}</span>
                              </td>
                              <td
                                v-if="
                                  item.guia !== null && item.guia !== undefined
                                "
                              >
                                {{ item.guia.numero }}-{{
                                  item.guia.digito_verificacion
                                }}
                              </td>
                              <td v-else class="text-center">
                                <span class="badge badge-default"
                                  >Sin asignar</span
                                >
                              </td>
                              <td class="text-center">
                                {{ item.vehiculo ? item.vehiculo.placa : "" }}
                                <div>
                                  <span
                                    v-if="item.vehiculo != null"
                                    class="badge ml-1 mr-1"
                                    :class="
                                      item.vehiculo.tipo_combustible == 1
                                        ? 'badge-warning'
                                        : 'badge-info'
                                    "
                                  >
                                    {{
                                      item.vehiculo
                                        ? item.vehiculo.nCombustible
                                        : ""
                                    }}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {{
                                  item.transportadora
                                    ? item.transportadora.razon_social
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  item.producto_liquido
                                    ? item.producto_liquido.nombre
                                    : ""
                                }}
                              </td>
                              <td>
                                <div v-if="item.sitio_cargue != null">
                                  {{ item.sitio_cargue.nombre }}
                                </div>
                              </td>
                              <td>
                                {{
                                  item.sitio_descargue
                                    ? item.sitio_descargue.nombre
                                    : ""
                                }}
                              </td>
                              <td>
                                {{ item.sitio ? item.sitio.nombre : "" }}
                              </td>
                              <td
                                class="text-right"
                                :class="
                                  item.standby_estado_cargue == 'normal'
                                    ? 'bg-success'
                                    : item.standby_estado_cargue == 'adecuado'
                                    ? 'bg-lime'
                                    : item.standby_estado_cargue == 'critico'
                                    ? 'bg-warning'
                                    : item.standby_estado_cargue ==
                                      'muy_critico'
                                    ? 'bg-orange'
                                    : item.standby_estado_cargue == 'genero'
                                    ? 'bg-danger'
                                    : 'bg-secondary'
                                "
                              >
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.standby_horas_cargue }}</span
                                >
                              </td>
                              <td class="text-right">
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.standby_valor_cargue }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.tiempos.cargue.fecha_enturne }}</span
                                >
                              </td>
                              <td class="text-center">
                                {{ item.tiempoCagueStandby }}
                              </td>
                              <td>
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.tiempos.cargue.fecha_maxima }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.tiempos.cargue.fecha }}</span
                                >
                              </td>
                              <td
                                class="text-right"
                                :class="
                                  item.standby_estado_descargue == 'normal'
                                    ? 'bg-success'
                                    : item.standby_estado_descargue ==
                                      'adecuado'
                                    ? 'bg-lime'
                                    : item.standby_estado_descargue == 'critico'
                                    ? 'bg-warning'
                                    : item.standby_estado_descargue ==
                                      'muy_critico'
                                    ? 'bg-orange'
                                    : item.standby_estado_descargue == 'genero'
                                    ? 'bg-danger'
                                    : 'bg-secondary'
                                "
                              >
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.standby_horas_descargue }}</span
                                >
                              </td>
                              <td class="text-right">
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.standby_valor_descargue }}</span
                                >
                              </td>
                              <td class="text-center">
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.fecha_vencimiento_guia }}</span
                                >
                              </td>
                              <td class="text-center">
                                {{ item.tiempoDescagueStandby }}
                              </td>
                              <td>
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{
                                    item.tiempos.descargue.fecha_maxima
                                  }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                  >{{ item.tiempos.descargue.fecha }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="badge badge-default"
                                  style="font-size: 1em"
                                >
                                  {{ item.estadoViaje }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Modal de filtro por fila -->
              <div class="modal fade" id="Modal_fechas">
                <div
                  class="modal-dialog"
                  :class="!filtros_fila.guia_id ? 'modal-lg' : 'modal-md'"
                >
                  <div class="modal-content">
                    <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                      <h4 class="modal-title text-white">Título</h4>
                      <button
                        type="button"
                        class="close text-white"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-modal"
                        ref="closeModal"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row float-center">
                        <div
                          class="form-group col-md-6"
                          v-if="!filtros_fila.guia_id"
                        >
                          <label>Estimado en Cargue</label>
                          <div class="row">
                            <div class="col-md-6">
                              <small>Enturne</small>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.cargue_fecha_enturne"
                                @input="validaFechasCargueFila()"
                              />
                              <!-- <input
                                type="time"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.cargue_hora_enturne"
                                @input="validaFechasCargueFila()"
                              /> -->
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.cargue_hora_enturne"
                                :class="
                                  $v.filtros_fila.cargue_hora_enturne.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }"
                                @input="validaFechasCargueFila()"
                              />
                            </div>
                            <div class="col-md-6">
                              <small>Cargue</small>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.cargue_fecha"
                                @input="validaFechasCargueFila()"
                              />
                              <!-- <input
                                type="time"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.cargue_hora"
                                @input="validaFechasCargueFila()"
                              /> -->
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.cargue_hora"
                                :class="
                                  $v.filtros_fila.cargue_hora.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }"
                                @input="validaFechasCargueFila()"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group"
                          :class="
                            !filtros_fila.guia_id ? 'col-md-6' : 'col-md-12'
                          "
                        >
                          <label>Estimado en Descargue</label>
                          <div class="row">
                            <div class="col-md-6">
                              <small>Enturne</small>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.descargue_fecha_enturne"
                                @input="validaFechasDescargueFila()"
                              />
                              <!-- <input
                                type="time"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.descargue_hora_enturne"
                                @input="validaFechasDescargueFila()"
                              /> -->
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.descargue_hora_enturne"
                                :class="
                                  $v.filtros_fila.descargue_hora_enturne
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }"
                                @input="validaFechasDescargueFila()"
                              />
                            </div>
                            <div class="col-md-6">
                              <small>Descargue</small>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.descargue_fecha"
                                @input="validaFechasDescargueFila()"
                              />
                              <!-- <input
                                type="time"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.descargue_hora"
                                @input="validaFechasDescargueFila()"
                              /> -->
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="filtros_fila.descargue_hora"
                                :class="
                                  $v.filtros_fila.descargue_hora.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                v-mask="{
                                  mask: '99:99',
                                  hourFormat: '24',
                                }"
                                @input="validaFechasDescargueFila()"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="getFila"
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required, helpers } from "vuelidate/lib/validators";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "ProyeccionStandbyIndex",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      viajes: {},
      total_viajes: null,
      calculos: null,
      datos_excel: null,
      semaforo_tiempos: {
        cargue: null,
        descargue: null,
      },
      filtros: {
        vehiculo: [],
        vehiculo_id: [],
        check_guia: false,
      },
      filtros_fila: {},
      filtros_estado_cargue: [],
      filtros_estado_descargue: [],
      conductor: null,
      tipo_combustible: null,
      vehiculo: null,
      remolque: null,
      transportadora: null,
      ruta: null,
      producto: null,
      sitio_cargue: null,
      sitio_descargue: null,
      ultimo_punto: null,
      listasForms: {
        estados: [],
        sitios: [],
        conductores: [],
        productos: [],
        rutas: [],
        remolques: [],
        empresas: [],
        puntos_gestion: [],
        sitio_types: [],
        vehiculos: [],
      },
    };
  },
  validations: {
    filtros: {
      cargue_hora_enturne: {
        timeFormat,
      },
      cargue_hora: {
        timeFormat,
      },
      descargue_hora: {
        timeFormat,
      },
    },
    filtros_fila: {
      cargue_hora_enturne: {
        timeFormat,
      },
      cargue_hora: {
        timeFormat,
      },
      descargue_hora_enturne: {
        timeFormat,
      },
      descargue_hora: {
        timeFormat,
      },
    },
  },
  computed: {
    filtrarEstados() {
      if (this.viajes.data) {
        return this.viajes.data.filter((viaje) => {
          this.filtros_estado_cargue = [];
          const estadosCargue = document.getElementsByClassName("checkCargue");
          estadosCargue.forEach((estado) => {
            if (estado.checked) {
              this.filtros_estado_cargue.push(estado.value);
            }
          });
          const searchCargue = this.filtros_estado_cargue;

          this.filtros_estado_descargue = [];
          const estadosDescargue =
            document.getElementsByClassName("checkDescargue");
          estadosDescargue.forEach((estado) => {
            if (estado.checked) {
              this.filtros_estado_descargue.push(estado.value);
            }
          });
          const searchDescargue = this.filtros_estado_descargue;

          if (searchCargue.length > 0 || searchDescargue.length > 0) {
            return (
              searchCargue.includes(viaje.standby_estado_cargue) ||
              searchDescargue.includes(viaje.standby_estado_descargue)
            );
          } else {
            return true;
          }
        });
      }
      return false;
    },
  },
  methods: {
    getIndex(page = 1) {
      this.viajes = {};
      this.cargando = true;
      this.total_viajes = null;
      (this.semaforo_tiempos = {
        cargues: null,
        descargues: null,
      }),
        axios
          .get("/api/hidrocarburos/proyeccionStandby?page=" + page, {
            params: this.filtros,
          })
          .then((response) => {
            this.calculos = response.data.calculos;
            this.math_calculos = response.data.math_calculos;

            this.getData(
              response.data.viajes,
              response.data.calculos,
              response.data.tiempos
            );

            // Se asigna el valor a mosstrar de llosemáforos de tiempos
            this.semaforo_tiempos = {};
            if (response.data.semaforo_tiempos) {
              this.semaforo_tiempos = response.data.semaforo_tiempos;
            }

            // Se calculan los datos de la consulta para enviar en el archivo Excel
            this.getDataExcel(
              response.data.result_viajes,
              response.data.calculos,
              response.data.tiempos
            );
          })
          .catch((error) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
    },

    buscarVehiculos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url, {})
          .then(function (response) {
            me.listasForms.vehiculos = response.data;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectVehiculo() {
      this.filtros.vehiculo_id = this.filtros.vehiculo.map((e) => e.id);
    },

    getData(viajes, calculos, tiempos) {
      this.cargando = false;

      // Se ajusta la vista con los datos calculados
      viajes.data.forEach((viaje) => {
        // Se valida si existe datos para asignar valores en la vista
        viaje.standby_horas_cargue = null;
        viaje.standby_valor_cargue = null;
        viaje.standby_horas_descargue = null;
        viaje.standby_valor_descargue = null;
        if (calculos[viaje.id]) {
          // Se valida si hay datos para el cargue
          if (calculos[viaje.id]["cargue"]) {
            if (calculos[viaje.id]["cargue"]["horas_costo"] != null) {
              viaje.standby_horas_cargue = parseFloat(
                calculos[viaje.id]["cargue"]["horas_costo"]
              ).toFixed(2);
            } else {
              viaje.standby_horas_cargue = `Sin tarifa asignada`;
            }

            viaje.standby_valor_cargue = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              calculos[viaje.id]["cargue"]["valor_costo"]
            );
            viaje.standby_estado_cargue =
              calculos[viaje.id]["cargue"]["estado"];
          }

          // Se valida si hay datos para el descargue
          if (calculos[viaje.id]["descargue"]) {
            if (calculos[viaje.id]["descargue"]["horas_costo"] != null) {
              viaje.standby_horas_descargue = parseFloat(
                calculos[viaje.id]["descargue"]["horas_costo"]
              ).toFixed(2);
            } else {
              viaje.standby_horas_descargue = `Sin tarifa asignada`;
            }
            viaje.standby_valor_descargue = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              calculos[viaje.id]["descargue"]["valor_costo"]
            );
            viaje.standby_estado_descargue =
              calculos[viaje.id]["descargue"]["estado"];
          }
        }

        // Se asignan las fechas de la proposición
        viaje.tiempos = tiempos[viaje.id];
        viaje.fecha_vencimiento_guia = null;
        if (viaje.trazas_fechas_viaje) {
          viaje.fecha_vencimiento_guia =
            viaje.trazas_fechas_viaje.fecha_fin_guia;
        }
      });

      this.viajes = viajes;

      // Se asignan los valores totales de encontrarlos
      if (calculos.totales) {
        if (calculos.totales.total_cargue) {
          calculos.totales.total_cargue = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            calculos.totales.total_cargue
          );
        }

        if (calculos.totales.total_descargue) {
          calculos.totales.total_descargue = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            calculos.totales.total_descargue
          );
        }
        calculos.totales.total_general = this.formatCurrency(
          "es-CO",
          "COP",
          2,
          calculos.totales.total_general
        );
        this.total_viajes = calculos.totales;
      }
    },

    getDataExcel(viajes, calculos, tiempos) {
      // Se ajusta la vista con los datos calculados
      viajes.forEach((viaje, index) => {
        // Se valida si existe datos para asignar valores en la vista
        viaje.standby_horas_cargue = null;
        viaje.standby_valor_cargue = null;
        viaje.standby_horas_descargue = null;
        viaje.standby_valor_descargue = null;
        if (calculos[viaje.id]) {
          // Se valida si hay datos para el cargue
          if (calculos[viaje.id]["cargue"]) {
            if (calculos[viaje.id]["cargue"]["horas_costo"] != null) {
              viaje.standby_horas_cargue = parseFloat(
                calculos[viaje.id]["cargue"]["horas_costo"]
              ).toFixed(2);
            } else {
              viaje.standby_horas_cargue = `Sin tarifa asignada`;
            }
            viaje.standby_valor_cargue = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              calculos[viaje.id]["cargue"]["valor_costo"]
            );
            viaje.standby_estado_cargue =
              calculos[viaje.id]["cargue"]["estado"];
          }

          // Se valida si hay datos para el descargue
          if (calculos[viaje.id]["descargue"]) {
            if (calculos[viaje.id]["descargue"]["horas_costo"] != null) {
              viaje.standby_horas_descargue = parseFloat(
                calculos[viaje.id]["descargue"]["horas_costo"]
              ).toFixed(2);
            } else {
              viaje.standby_horas_descargue = `Sin tarifa asignada`;
            }
            viaje.standby_valor_descargue = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              calculos[viaje.id]["descargue"]["valor_costo"]
            );
            viaje.standby_estado_descargue =
              calculos[viaje.id]["descargue"]["estado"];
          }
        }

        // Se asignan las fechas de la proposición
        viaje.tiempos = tiempos[viaje.id];
        viaje.fecha_vencimiento_guia = null;
        if (viaje.trazas_fechas_viaje) {
          viaje.fecha_vencimiento_guia =
            viaje.trazas_fechas_viaje.fecha_fin_guia;
        }
      });

      this.datos_excel = viajes;
    },

    limpiar() {
      this.filtros = {};
      this.conductor = null;
      this.vehiculo = null;
      this.remolque = null;
      this.transportadora = null;
      this.ruta = null;
      this.producto = null;
      this.sitio_cargue = null;
      this.sitio_descargue = null;
      this.ultimo_punto = null;
      this.getIndex();
    },

    getSitioType() {
      // sitio 1, punto gestion 2
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_types = response.data;
      });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tiposCombustible = response.data;
      });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.remolques = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.rutas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarProductos() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.productos = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductores() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.conductores = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function (response) {
          me.listasForms.empresas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarPuntosGestion() {
      let me = this;
      var url = "api/admin/puntos_gestion/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.puntos_gestion = respuesta;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTipoCombustible() {
      this.filtros.tipo_combustible = this.tipo_combustible
        ? this.tipo_combustible.numeracion
        : null;
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarRemolque() {
      if (this.remolque != null) {
        this.filtros.remolque_id = this.remolque.id;
      } else {
        this.filtros.remolque_id = null;
      }
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.transportadora_id = this.transportadora.id;
      } else {
        this.filtros.transportadora_id = null;
      }
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta_id = this.ruta.id;
      } else {
        this.filtros.ruta_id = null;
      }
    },

    seleccionarProducto() {
      if (this.producto != null) {
        this.filtros.producto_id = this.producto.id;
      } else {
        this.filtros.producto_id = null;
      }
    },

    seleccionarSitioCargue() {
      if (this.sitio_cargue != null) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      } else {
        this.filtros.sitio_cargue_id = null;
      }
    },

    seleccionarSitioDescargue() {
      if (this.sitio_descargue != null) {
        this.filtros.sitio_descargue_id = this.sitio_descargue.id;
      } else {
        this.filtros.sitio_descargue_id = null;
      }
    },

    seleccionarSitio() {
      if (this.filtros.sitio_type == 1 || this.filtros.sitio_type == 2) {
        if (this.ultimo_punto != null) {
          this.filtros.sitio_id = this.ultimo_punto.id;
        } else {
          this.filtros.sitio_id = null;
        }
      }
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechasCargue() {
      const fecha_enturne = `${this.filtros.cargue_fecha_enturne} ${this.filtros.cargue_hora_enturne}`;
      const fecha_cargue = `${this.filtros.cargue_fecha} ${this.filtros.cargue_hora}`;
      this.validaFechas(fecha_enturne, fecha_cargue, "Cargue");
    },

    validaFechasDescargue() {
      const fecha_enturne = `${this.filtros.descargue_fecha_enturne} ${this.filtros.descargue_hora_enturne}`;
      const fecha_descargue = `${this.filtros.descargue_fecha} ${this.filtros.descargue_hora}`;
      this.validaFechas(fecha_enturne, fecha_descargue, "Descargue");
    },

    getDataFila(viaje_id, guia_id) {
      this.filtros_fila = {
        viaje_id,
        guia_id,
        cargue_fecha_enturne: null,
        cargue_hora_enturne: null,
        cargue_fecha: null,
        cargue_hora: null,
        descargue_fecha_enturne: null,
        descargue_hora_enturne: null,
        descargue_fecha: null,
        descargue_hora: null,
      };
    },

    getFila() {
      let me = this;

      if (
        (this.filtros_fila.cargue_fecha_enturne &&
          this.filtros_fila.cargue_hora_enturne) ||
        (this.filtros_fila.cargue_fecha && this.filtros_fila.cargue_hora) ||
        (this.filtros_fila.descargue_fecha_enturne &&
          this.filtros_fila.descargue_hora_enturne) ||
        (this.filtros_fila.descargue_fecha && this.filtros_fila.descargue_hora)
      ) {
        this.cargando = true;
        axios
          .get("/api/hidrocarburos/proyeccionStandby/getDataFila", {
            params: this.filtros_fila,
          })
          .then((response) => {
            const viaje_id = this.filtros_fila.viaje_id;
            let calculo_anterior = this.calculos[viaje_id];
            let calculo_nuevo = response.data.calculos[viaje_id];

            // Se reacalcula los totales
            let totales = this.math_calculos;
            const total_cargue = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              totales.total_cargue -
                calculo_anterior.cargue.valor_costo +
                calculo_nuevo.cargue.valor_costo
            );
            const total_descargue = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              totales.total_descargue -
                calculo_anterior.descargue.valor_costo +
                calculo_nuevo.descargue.valor_costo
            );
            const total_general = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              total_cargue + total_descargue
            );
            this.total_viajes = {
              total_cargue,
              total_descargue,
              total_general,
            };

            // Se ajusta los valores del semáforo
            // SEMAFORO DE CARGUES
            // Se resta semaforo el valor anterior
            switch (calculo_anterior.cargue.estado) {
              case "normal":
                this.semaforo_tiempos.cargue.normal.cantidad--;
                break;
              case "adecuado":
                this.semaforo_tiempos.cargue.adecuado.cantidad--;
                break;
              case "critico":
                this.semaforo_tiempos.cargue.critico.cantidad--;
                break;
              case "muy_critico":
                this.semaforo_tiempos.cargue.muy_critico.cantidad--;
                break;
              case "genero":
                this.semaforo_tiempos.cargue.genero.cantidad--;
                break;
              case "sin_tarifa":
                this.semaforo_tiempos.cargue.sin_tarifa.cantidad--;
                break;
              default:
                break;
            }

            // Se suma semaforo el valor nuevo
            switch (calculo_nuevo.cargue.estado) {
              case "normal":
                this.semaforo_tiempos.cargue.normal.cantidad++;
                break;
              case "adecuado":
                this.semaforo_tiempos.cargue.adecuado.cantidad++;
                break;
              case "critico":
                this.semaforo_tiempos.cargue.critico.cantidad++;
                break;
              case "muy_critico":
                this.semaforo_tiempos.cargue.muy_critico.cantidad++;
                break;
              case "genero":
                this.semaforo_tiempos.cargue.genero.cantidad++;
                break;
              case "sin_tarifa":
                this.semaforo_tiempos.cargue.sin_tarifa.cantidad++;
                break;
              default:
                break;
            }

            // SEMAFORO DE DESCARGUES
            // Se resta semaforo el valor anterior
            switch (calculo_anterior.descargue.estado) {
              case "normal":
                this.semaforo_tiempos.descargue.normal.cantidad--;
                break;
              case "adecuado":
                this.semaforo_tiempos.descargue.adecuado.cantidad--;
                break;
              case "critico":
                this.semaforo_tiempos.descargue.critico.cantidad--;
                break;
              case "muy_critico":
                this.semaforo_tiempos.descargue.muy_critico.cantidad--;
                break;
              case "genero":
                this.semaforo_tiempos.descargue.genero.cantidad--;
                break;
              case "sin_tarifa":
                this.semaforo_tiempos.descargue.sin_tarifa.cantidad--;
                break;
              default:
                break;
            }

            // Se suma semaforo el valor nuevo
            switch (calculo_nuevo.descargue.estado) {
              case "normal":
                this.semaforo_tiempos.descargue.normal.cantidad++;
                break;
              case "adecuado":
                this.semaforo_tiempos.descargue.adecuado.cantidad++;
                break;
              case "critico":
                this.semaforo_tiempos.descargue.critico.cantidad++;
                break;
              case "muy_critico":
                this.semaforo_tiempos.descargue.muy_critico.cantidad++;
                break;
              case "genero":
                this.semaforo_tiempos.descargue.genero.cantidad++;
                break;
              case "sin_tarifa":
                this.semaforo_tiempos.descargue.sin_tarifa.cantidad++;
                break;
              default:
                break;
            }

            // SE ACTUALIZAN LOS DATOS DE LA PROYECCIÓN
            let tiempo = response.data.tiempos[viaje_id];
            let viaje = this.viajes.data.find((viaje) => viaje.id == viaje_id);
            let idxviaje = this.viajes.data.findIndex(
              (viaje) => viaje.id == viaje_id
            );

            // DATOS DEL CARGUE
            if (
              (this.filtros_fila.cargue_fecha &&
                this.filtros_fila.cargue_fecha_enturne) ||
              (this.filtros_fila.cargue_hora &&
                this.filtros_fila.cargue_hora_enturne)
            ) {
              viaje.standby_estado_cargue = calculo_nuevo.cargue.estado;
              viaje.standby_horas_cargue = calculo_nuevo.cargue.horas_costo;
              viaje.standby_valor_cargue = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                calculo_nuevo.cargue.valor_costo
              );

              if (tiempo.cargue.fecha_enturne) {
                viaje.tiempos.cargue.fecha_enturne =
                  tiempo.cargue.fecha_enturne;
              }
              if (tiempo.cargue.fecha_limite) {
                viaje.tiempos.cargue.fecha_limite = tiempo.cargue.fecha_limite;
              }
              if (tiempo.cargue.fecha) {
                viaje.tiempos.cargue.fecha = tiempo.cargue.fecha;
              }
            }

            // DATOS DEL DESCARGUE
            if (
              (this.filtros_fila.descargue_fecha &&
                this.filtros_fila.descargue_hora) ||
              (this.filtros_fila.descargue_fecha_enturne &&
                this.filtros_fila.descargue_hora_enturne)
            ) {
              viaje.standby_estado_descargue = calculo_nuevo.descargue.estado;
              viaje.standby_horas_descargue =
                calculo_nuevo.descargue.horas_costo;
              viaje.standby_valor_descargue = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                calculo_nuevo.descargue.valor_costo
              );

              if (tiempo.descargue.fecha_enturne) {
                viaje.tiempos.descargue.fecha_enturne =
                  tiempo.descargue.fecha_enturne;
              }
              if (tiempo.descargue.fecha_limite) {
                viaje.tiempos.descargue.fecha_limite =
                  tiempo.descargue.fecha_limite;
              }
              if (tiempo.descargue.fecha) {
                viaje.tiempos.descargue.fecha = tiempo.descargue.fecha;
              }
            }

            // Se reasigna el valor anterior
            this.calculos[viaje_id] = response.data.calculos[viaje_id];
            this.cargando = false;
            this.$refs.closeModal.click();
          })
          .catch((error) => {
            this.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        me.$swal({
          icon: "error",
          title: `Debe diligenciar correctammente el fomulario para generar la búsqueda`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    validaFechasCargueFila() {
      const fecha_enturne = `${this.filtros_fila.cargue_fecha_enturne} ${this.filtros_fila.cargue_hora_enturne}`;
      const fecha_cargue = `${this.filtros_fila.cargue_fecha} ${this.filtros_fila.cargue_hora}`;
      this.validaFechas(fecha_enturne, fecha_cargue, "CargueFila");
    },

    validaFechasDescargueFila() {
      const fecha_enturne = `${this.filtros_fila.descargue_fecha_enturne} ${this.filtros_fila.descargue_hora_enturne}`;
      const fecha_descargue = `${this.filtros_fila.descargue_fecha} ${this.filtros_fila.descargue_hora}`;
      this.validaFechas(fecha_enturne, fecha_descargue, "DescargueFila");
    },

    validaFechas(fecha_menor, fecha_mayor, tipo) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        switch (tipo) {
          case "Cargue":
            this.filtros.cargue_fecha = null;
            this.filtros.cargue_hora = null;
            break;

          case "Descargue":
            this.filtros.descargue_fecha = null;
            this.filtros.descargue_hora = null;
            break;

          case "CargueFila":
            this.filtros_fila.cargue_fecha = null;
            this.filtros_fila.cargue_hora = null;
            break;

          case "DescargueFila":
            this.filtros_fila.descargue_fecha = null;
            this.filtros_fila.descargue_hora = null;
            break;
        }

        this.$swal({
          icon: "error",
          title: `La fecha de enturne de ${tipo} no puede ser mayor a la fecha de ${tipo}...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formatted = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);
      return formatted;
    },

    generarListadoExcel() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("datos_excel", JSON.stringify(this.datos_excel));
      formData.append("semaforo", JSON.stringify(this.semaforo_tiempos));
      formData.append("totales", JSON.stringify(this.calculos.totales));

      axios
        .post("/api/hidrocarburos/proyeccionStandby/export", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.buscarRemolques();
    this.buscarTransportadora();
    this.buscarConductores();
    this.getTiposCombustibles();
    this.buscarProductos();
    this.buscarRutas();
    this.getSitioType();
    this.buscarSitios();
    this.buscarPuntosGestion();
  },
};
</script>